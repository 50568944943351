import { type Statistics } from "~/repositories/statistics";
import { type ConvertToAllString, type dictionary } from "./type";
import { type ModifierList } from "~/repositories/modifier_list";
import { Image } from "~/repositories/image";

const modifierList: ConvertToAllString<ModifierList> = {
  name: "名稱",
  modifiers: "加成項",
  id: "ID",
  selfName: "加成項列表",
};

const statistics: ConvertToAllString<Statistics> = {
  id: "ID",
  rates: "評分",
  usageTimestamps: "被使用記錄",
  viewTimestamps: "被檢視記錄",
  monsterId: "",
  crystalId: "",
  mainWeaponId: "",
  subWeaponId: "",
  bodyArmorId: "",
  additionalEquipmentId: "",
  specialEquipmentId: "",
  skillId: "",
  petId: "",
  consumableId: "",
  characterId: "",
  analyzerId: "",
  selfName: "統計資訊",
};

const image: ConvertToAllString<Image> = {
  id: "ID",
  dataUrl: "DataUrl",
  main_weaponId: "",
  sub_weaponId: "",
  body_armorId: "",
  additional_equipmentId: "",
  special_equipmentId: "",
  selfName: "圖片",
}

const dictionary: dictionary = {
  ui: {
    searchPlaceholder: "這裡是搜尋框~",
    columnsHidden: "隱藏列",
    actions: {
      add: "新增",
      create: "建立",
      remove: "刪除",
      update: "更新",
      open: "開啟",
      upload: "上傳",
      save: "儲存",
      reset: "清空",
      modify: "修改",
      cancel: "取消",
      close: "關閉",
      back: "返回",
      filter: "過濾",
      generateImage: "生成圖片",
      swap: "替換",
      checkInfo: "查看詳情",
      zoomIn: "放大",
      zoomOut: "縮小",
    },
    nav: {
      home: "首頁",
      monsters: "怪物",
      skills: "技能",
      equipments: "裝備",
      crystals: "鍛晶",
      pets: "寵物",
      items: "消耗品",
      character: "角色配置",
      analyzer: "連擊分析",
    },
    errorPage: {
      tips: "你來到了沒有知識的荒原~，點擊屏幕返回",
    },
    settings: {
      title: "設定",
      userInterface: {
        title: "外觀",
        isAnimationEnabled: {
          title: "是否開啟動畫",
          description: "將影響所有頁面的過渡和動畫效果持續時間。",
        },
        is3DbackgroundDisabled: {
          title: "是否禁用3D背景",
          description: "可能會產生大量性能損耗，不推薦開啟。",
        }
      },
      language: {
        title: "語言",
        selectedLanguage: {
          title: "選擇語言",
          description: "影響所有的介面文本，但是無法改變資料類文本。",
          zhCN: "簡體中文",
          zhTW: "繁體中文",
          enUS: "English",
          jaJP: "日本語",
        },
      },
      statusAndSync: {
        title: "狀態與同步",
        restorePreviousStateOnStartup: {
          title: "啟動時恢復上一次的狀態",
          description: "暫未實現。",
        },
        syncStateAcrossClients: {
          title: "同步所有客戶端狀態",
          description: "此設定僅當使用者登入時生效，未登入時客戶端不具有身分標識，無法同步。",
        },
      },
      privacy: {
        title: "隱私",
        postVisibility: {
          title: "作品可見性",
          description:
            "作品可見性包括：角色、怪物、鍛晶、主武器、副武器、身體裝備、追加裝備、特殊裝備、寵物、技能、消耗品、連擊、分析器。",
          everyone: "所有人可見",
          friends: "僅好友可見",
          onlyMe: "僅自己可見",
        },
      },
      messages: {
        title: "訊息通知",
        notifyOnContentChange: {
          title: "以下內容發生變化時通知我",
          description: "暫未實現。",
          notifyOnReferencedContentChange: "引用內容改變時",
          notifyOnLike: "收到讚時",
          notifyOnBookmark: "作品被收藏時",
        },
      },
      about: {
        title: "關於此應用",
        description: {
          title: "描述",
          description: "沒想好怎麼寫。",
        },
        version: {
          title: "版本",
          description: "0.0.1-alpha",
        },
      },
    },
    index: {
      adventurer: "冒險者",
      goodMorning: "哦哈喵~ (=´ω｀=)",
      goodAfternoon: "下午好ヾ(=･ω･=)o",
      goodEvening: "晚上好(.-ω-)zzz",
      nullSearchResultWarring: "沒有找到相關內容!!!∑(ﾟДﾟノ)ノ",
      nullSearchResultTips: "變強之旅總有艱險阻道，求知路上不免遍佈荊棘\n但是這裡沒有\n搜尋結果裡沒有就是沒有",
    },
    monster: {
      pageTitle: "怪物",
      table: {
        title: "怪物資訊表",
        description: "不是所有怪物一開始就是怪物，也不是所有怪物看起來都像怪物。",
      },
      news: {
        title: "最近更新",
      },
      augmented: "是否展示全部星級資料",
      canNotModify: "系統生成，不可修改",
      monsterDegreeOfDifficulty: {
        0: "零星",
        1: "一星",
        2: "二星",
        3: "三星",
        4: "四星",
      },
      monsterForm: {
        description: "上傳定點boss資料時請使用一星數據，系統將以規則自動計算其餘星級資料。",
      },
    },
    crystal: {
      pageTitle: "鍛晶表",
      description: "正在開發中，請勿使用。",
      canNotModify: "系統生成，不可修改",
      crystalForm: {
        description: "阿拉啦",
      },
    },
    skill: {
      pageTitle: "技能資訊表",
      description: "此頁面正在開發中，請勿使用",
    },
    analyzer: {
      pageTitle: "流程計算器",
      description: "正在開發中，請勿使用",
      modifiers: "加成項",
      dialogData: {
        selfName: "属性",
        lv: "等級",
        mainWeapon: {
          type: "主武器型別",
          baseAtk: "主武器基礎攻擊力",
          refinement: "主武器精煉值",
          stability: "主武器穩定率",
          selfName: "主武器",
        },
        subWeapon: {
          type: "副武器型別",
          baseAtk: "副武器基礎攻擊力",
          refinement: "副武器精煉值",
          stability: "副武器穩定率",
          selfName: "副武器",
        },
        bodyArmor: {
          type: "身體裝備類型",
          baseDef: "身體裝備基礎防禦力",
          refinement: "身體裝備精煉值",
          selfName: "身體裝備",
        },
        str: "力量",
        int: "智力",
        vit: "耐力",
        agi: "敏捷",
        dex: "靈巧",
        luk: "幸運",
        cri: "爆擊",
        tec: "技巧",
        men: "異抗",
        pPie: "物理貫穿",
        mPie: "魔法貫穿",
        pStab: "物理穩定",
        sDis: "近距離威力",
        lDis: "遠距離威力",
        crC: "法術爆擊轉換率",
        cdC: "法術爆傷轉換率",
        weaponPatkT: "武器攻擊轉換率（物理）",
        weaponMatkT: "武器攻擊轉換率（魔法）",
        uAtk: "拔刀攻擊",
        stro: {
          LIGHT: "对光属性增强",
          NO_ELEMENT: "对無属性增强",
          DARK: "对暗属性增强",
          WATER: "对水属性增强",
          FIRE: "对火属性增强",
          EARTH: "对土属性增强",
          WIND: "对風属性增强",
          selfName: "对属增強",
        },
        total: "總傷害提升",
        final: "最終傷害提升",
        am: "行動速度",
        cm: "詠唱縮減",
        aggro: "仇恨值倍率",
        maxHp: "生命值上限",
        maxMp: "法力值上限",
        pCr: "物理暴擊",
        pCd: "物理爆傷",
        mainWeaponAtk: "主武器攻擊力",
        subWeaponAtk: "副武器攻擊力",
        weaponAtk: "武器攻擊力",
        pAtk: "物理攻擊",
        mAtk: "魔法攻擊",
        aspd: "攻擊速度",
        cspd: "詠唱速度",
        ampr: "攻回",
        hp: "當前生命值",
        mp: "當前法力值",
        name: "名稱",
        pDef: "物理防禦",
        pRes: "物理抗性",
        mDef: "魔法防禦",
        mRes: "魔法抗性",
        cRes: "暴擊抗性",
        anticipate: "看穿",

        index: "序號",
        skillEffectType: "讀取條類型",
        actionFixedDuration: "動畫固定畫面",
        actionModifiableDuration: "動畫可加速影格",
        skillActionFrames: "動畫長度總值",
        chantingFixedDuration: "固定詠唱時間",
        chantingModifiableDuration: "可加速詠唱時長",
        skillChantingFrames: "詠唱時長總值",
        chargingFixedDuration: "固定蓄力時長",
        chargingModifiableDuration: "可加速蓄力時長",
        skillChargingFrames: "蓄力長度總值",
        skillDuration: "技能總耗時",
        skillStartupFrames: "技能前搖",
        vMatk: "有效攻擊力（魔法）",
        vPatk: "有效物攻（物理）",
      },
      actualValue: "實際值",
      baseValue: "基礎值",
      staticModifiers: "常態加成",
      dynamicModifiers: "暫時加成",
      analyzerPage: {
        mobsConfig: {
          title: "目標怪物",
        },
        teamConfig: {
          title: "隊伍配置"
        }
      }
    },
    character: {
      pageTitle: "機體表",
      description: "此頁面正在開發中，請勿使用",
    },
  },
  db: {
    enums: {
      MonsterType: {
        COMMON_BOSS: "定點王",
        COMMON_MINI_BOSS: "野王",
        COMMON_MOBS: "小怪",
        EVENT_BOSS: "活動定點王",
        EVENT_MINI_BOSS: "活動野王",
        EVENT_MOBS: "活動小怪",
        selfName: "怪物類型",
      },
      Element: {
        NO_ELEMENT: "無屬性",
        DARK: "暗屬性",
        EARTH: "地屬性",
        FIRE: "火屬性",
        LIGHT: "光屬性",
        WATER: "水屬性",
        WIND: "風屬性",
        selfName: "屬性",
      },
      SpecialAbiType: {
        NOSPECIALABI: "無",
        LUK: "幸運",
        CRI: "暴擊",
        TEC: "技巧",
        MEN: "異抗",
        selfName: "特殊能力值類型",
      },
      MainWeaponType: {
        NO_WEAPON: "空",
        ONE_HAND_SWORD: "單手劍",
        TWO_HANDS_SWORD: "雙手劍",
        BOW: "弓",
        STAFF: "法杖",
        MAGIC_DEVICE: "魔導具",
        KNUCKLE: "拳套",
        HALBERD: "旋風槍",
        KATANA: "拔刀劍",
        BOWGUN: "弩",
        selfName: "主武器類型",
      },
      SubWeaponType: {
        NO_WEAPON: "空",
        ONE_HAND_SWORD: "單手劍",
        MAGIC_DEVICE: "魔導具",
        KNUCKLE: "拳套",
        KATANA: "拔刀劍",
        ARROW: "箭矢",
        DAGGER: "小刀",
        NINJUTSUSCROLL: "忍術捲軸",
        SHIELD: "盾牌",
        selfName: "副武器類型",
      },
      BodyArmorType: {
        NORMAL: "一般",
        LIGHT: "輕化",
        HEAVY: "重化",
        selfName: "防具類型",
      },
      CrystalType: {
        GENERAL: "通用鍛晶",
        WEAPONCRYSTAL: "武器鍛晶",
        BODYCRYSTAL: "防具鍛晶",
        ADDITIONALCRYSTAL: "追加鍛晶",
        SPECIALCRYSTAL: "特殊鍛晶",
        selfName: "鍛晶類型",
      },
      SkillType: {
        ACTIVE_SKILL: "主動技能",
        PASSIVE_SKILL: "被動技能",
        selfName: "技能類型",
      },
      SkillTreeName: {
        BLADE: "劍系技能樹",
        MAGIC: "魔法技能樹",
        SHOT: "射擊技能樹",
        MARTIAL: "格鬥技能樹",
        DUALSWORD: "雙劍技能樹",
        HALBERD: "斧槍技能樹",
        MONONOFU: "武士技能樹",
        CRUSHER: "粉碎者技能樹",
        SPRITE: "靈魂技能樹",
        selfName: "技能樹名稱",
      },
      UserRole: {
        USER: "常規使用者",
        ADMIN: "管理者",
        selfName: "使用者角色",
      },
      YieldType: {
        ImmediateEffect: "即時效果（只作用一次）",
        PersistentEffect: "持續型效果（在刪除前，一直有效）",
        selfName: "效果類型",
      },
      WeaponElementDependencyType: {
        EXTEND: "繼承",
        UNEXTEND: "不繼承",
        selfName: "是否繼承武器元素類型",
      },
      ComboType: {
        NULL: "未設定",
        selfName: "連擊類型",
      },
      SkillExtraActionType: {
        None: "無",
        Chanting: "詠唱",
        Charging: "蓄力",
        selfName: "技能額外動作類型",
      },
      CharacterType: {
        Tank: "坦克",
        Mage: "",
        Ranger: "",
        Marksman: "",
        selfName: "角色類型",
      },
      selfName: "枚舉類型",
    },
    models: {
      monster: {
        selfName: "怪物",
        id: "ID",
        name: "名稱",
        monsterType: "類型",
        baseLv: "等級",
        experience: "經驗",
        address: "所在地址",
        element: "元素屬性",
        radius: "半徑",
        maxhp: "最大HP",
        physicalDefense: "物理防禦",
        physicalResistance: "物理抗性",
        magicalDefense: "魔法防禦",
        magicalResistance: "魔法抗性",
        criticalResistance: "暴擊抗性",
        avoidance: "迴避值",
        dodge: "閃避率",
        block: "格擋率",
        normalAttackResistanceModifier: "一般慣性變動率",
        physicalAttackResistanceModifier: "物理慣性變動率",
        magicalAttackResistanceModifier: "魔法慣性變動率",
        difficultyOfTank: "難度：坦職",
        difficultyOfMelee: "難度：近戰",
        difficultyOfRanged: "難度：遠端",
        possibilityOfRunningAround: "好動程度",
        extraDetails: "額外說明",
        dataSources: "資料來源",
        createdByUserId: "建立者Id",
        updatedByUserId: "更新者Id",
        createdAt: "創建於",
        updatedAt: "更新於",
        statistics: statistics,
        statisticsId: "統計ID",
        image: image,
        imageId: "圖片ID",
      },
      crystal: {
        selfName: "鍛晶",
        id: "ID",
        modifierList: modifierList,
        name: "鍛晶名稱",
        crystalType: "鍛晶類型",
        front: "前置鍛晶數",
        modifierListId: "加成項目清單ID",
        extraDetails: "額外說明",
        dataSources: "資料來源",
        createdByUserId: "建立者Id",
        updatedByUserId: "更新者Id",
        createdAt: "創建於",
        updatedAt: "更新於",
        statistics: statistics,
        statisticsId: "統計ID",
      },
      skill: {
        selfName: "技能",
        id: "ID",
        name: "名稱",
        skillType: "型別",
        skillTreeName: "所屬技能樹",
        weaponElementDependencyType: "屬性是否繼承武器",
        element: "自身元素屬性",
        skillEffect: "技能效果",
        skillDescription: "技能說明",
        extraDetails: "額外說明",
        dataSources: "資料來源",
        createdByUserId: "建立者Id",
        updatedByUserId: "更新者Id",
        createdAt: "創建於",
        updatedAt: "更新於",
        statistics: statistics,
        statisticsId: "統計ID",
      },
      user: {
        selfName: "使用者",
        id: "帳號ID",
        name: "使用者名稱",
        email: "郵件地址",
        emailVerified: "郵件邀請時間",
        image: "頭像",
        userRole: "身分類型",
      },
      skillEffect: {
        selfName: "技能效果",
        id: "ID",
        condition: "生效條件",
        description: "條件說明",
        actionBaseDurationFormula: "固定動作長度表達式（幀）",
        actionModifiableDurationFormula: "可加速動作時長表達式（幀）",
        skillExtraActionType: "額外動作",
        chargingBaseDurationFormula: "固定蓄力時長式表達式（秒）",
        chargingModifiableDurationFormula: "可加速蓄力時長表達式（秒）",
        chantingBaseDurationFormula: "固定詠唱時長表達式（秒）",
        chantingModifiableDurationFormula: "可加速詠唱時長表達式（秒）",
        skillStartupFramesFormula: "技能前搖表達式（秒）",
        belongToskillId: "所屬技能",
        skillCost: "消耗项",
        skillYield: "作用项",
      },
      skillCost: {
        selfName: "技能消耗",
        id: "ID",
        costFormula: "計算公式",
        skillEffectId: "所屬技能效果",
        name: "名稱",
      },
      skillYield: {
        selfName: "技能作用",
        id: "ID",
        name: "名稱",
        yieldType: "效果類型",
        mutationTimingFormula: "效果改變的時機計算公式",
        yieldFormula: "效果計算公式",
        skillEffectId: "所屬技能效果",
      },
      character: {
        selfName: "角色",
        id: "Id",
        name: "名稱",
        lv: "等級",
        characterType: "角色類型",
        baseStr: "基礎力量",
        baseInt: "基礎智力",
        baseVit: "基礎耐力",
        baseAgi: "基礎敏捷",
        baseDex: "基礎靈巧",
        specialAbiType: "特殊屬性類型",
        specialAbiValue: "特殊屬性值",
        mainWeapon: {
          selfName: "主武器",
          crystalList: "鍛晶",
          id: "ID",
          name: "名稱",
          mainWeaponType: "主武器類型",
          baseAtk: "基礎面板",
          refinement: "精煉值",
          stability: "穩定率",
          element: "元素屬性",
          modifierList: modifierList,
          modifierListId: "附魔ID",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        mainWeaponId: "主武器Id",
        subWeapon: {
          selfName: "副武器",
          modifierList: modifierList,
          id: "ID",
          name: "名稱",
          subWeaponType: "副武器類型",
          baseAtk: "基礎面板",
          refinement: "精煉值",
          stability: "穩定率",
          element: "元素屬性",
          modifierListId: "",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        subWeaponId: "",
        bodyArmor: {
          selfName: "身體裝備",
          modifierList: modifierList,
          crystalList: "鍛晶",
          id: "ID",
          name: "名稱",
          bodyArmorType: "防具類型",
          refinement: "精煉值",
          baseDef: "基礎防禦力",
          modifierListId: "",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        bodyArmorId: "",
        additionalEquipment: {
          selfName: "追加裝備",
          modifierList: modifierList,
          crystalList: "鍛晶",
          id: "ID",
          name: "名稱",
          refinement: "精煉值",
          modifierListId: "",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        additionalEquipmentId: "",
        specialEquipment: {
          selfName: "特殊裝備",
          modifierList: modifierList,
          crystalList: "鍛晶",
          id: "ID",
          name: "名稱",
          modifierListId: "",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        specialEquipmentId: "",
        fashion: modifierList,
        fashionModifierListId: "",
        cuisine: modifierList,
        cuisineModifierListId: "",
        consumableList: "消耗品列表",
        skillList: "技能清單",
        combos: "連擊列表",
        pet: {
          selfName: "寵物",
          id: "ID",
          name: "名稱",
          extraDetails: "額外說明",
          dataSources: "資料來源",
          createdByUserId: "建立者Id",
          updatedByUserId: "更新者Id",
          createdAt: "創建於",
          updatedAt: "更新於",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        petId: "寵物Id",
        modifierList: modifierList,
        modifierListId: "額外加成Id",
        extraDetails: "額外說明",
        createdByUserId: "建立者Id",
        updatedByUserId: "更新者Id",
        createdAt: "創建於",
        updatedAt: "更新於",
        statistics: statistics,
        statisticsId: "統計ID",
        imageId: "圖片Id",
      },
    },
  },
};

export default dictionary;
